import React from 'react'
import Skeleton from "react-loading-skeleton";

const CategorySkeleton = (props) => {
  const { styles } = props;

  return (
    <>
      <div className={styles.skeleton}>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-6 col-md-4">
                <Skeleton count={1} height={150} />
                <p>
                  <Skeleton count={3} height={20} />
                </p>
              </div>
              <div className="col-6 col-md-4">
                <Skeleton count={1} height={150} />
                <p>
                  <Skeleton count={3} height={20} />
                </p>
              </div>
              <div className="col-6 col-md-4">
                <Skeleton count={1} height={150} />
                <p>
                  <Skeleton count={3} height={20} />
                </p>
              </div>
              <div className="col-6 col-md-4">
                <Skeleton count={1} height={150} />
                <p>
                  <Skeleton count={3} height={20} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategorySkeleton