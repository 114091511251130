export const generateBreadcrumbs = (router, websiteurl, CatProdType=false) => {
    // Remove any query parameters, as those aren't included in breadcrumbs
    const asPathWithoutQuery = router.asPath.split("?")[0];

    // Break down the path between "/"s, removing empty entities
    // Ex:"/my/nested/path" --> ["my", "nested", "path"]
    const asPathNestedRoutes = asPathWithoutQuery.split("/")
        .filter(v => v.length > 0).filter(v => v !== 'international');

    // Iterate over the list of nested route parts and build
    // a "crumb" object for each one.
    const crumblist = asPathNestedRoutes.map((subpath, idx) => {
        // We can get the partial nested route for the crumb
        // by joining together the path parts up to this point.

        let url_path = asPathNestedRoutes.slice(0, idx + 1).join("/");
        url_path = url_path.replace(/.html/g, ""); // Remove .html extension

        let href = `${websiteurl}${url_path}`;
        if (CatProdType) {
            href = `${href}.html`;
        }
        // The title will just be the route string for now
        const title = subpath.replace(/.html/g, "").replace(/-/g, " ");

        return { href, title, url_path };
    })

    // Add in a default "Home" crumb for the top-level
    return [{ href: websiteurl, title: "Home", url_path: '' }, ...crumblist];
}