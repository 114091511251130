import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";
import dynamic from "next/dist/shared/lib/dynamic";

const FAQComponent = dynamic(() => import('./FAQComponent'))

const FAQSection = ({ preFixUrl, productID, urlKey }) => {
    const { ref: faqSectionRef, inView: faqSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={faqSectionRef} className={styles.pdp_sec13}>
            {
                faqSectionRefView ? <FAQComponent
                    preFixUrl={preFixUrl}
                    productID={productID}
                    styles={styles}
                    urlKey={urlKey} /> : null
            }
        </div>
    )
}

export default FAQSection;