import { Children } from "react";

// UI issue on international and domestic, resolve using Children - TODO - need to check root cause why do we need to use children here to resolve that css issue on domestic and internatioanl
const ATCBuyNowButton = ({ children }) => {
    return (
        <div className="RowList">
            {Children.map(children, child => {
                return (
                    <div className="Row">
                        {child}
                    </div>
                )
            }
            )}
        </div>
    )

    // Another way to do, commenting for reference
    // return (
    //     <div className="Rowlist">
    //         {Children.map(children, (child, index) => cloneElement(child, null, children))}
    //     </div>
    // )
}

export default ATCBuyNowButton;