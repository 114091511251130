import cancel_btn from '~/public/images/cancel-btn.inline.svg';

const Filters = ({ styles, sortValue , aggregationsshow, selectwidth, handleChange, FiltersApplied, bIsFiltersApplied, toggleDrawer, cancelFilters }) => {
    return (
        <div style={{ minHeight: 35 }} className={styles.category_sort}>
            <div className={styles.select_size}>
                {aggregationsshow && <a
                    className={bIsFiltersApplied ? styles.active : ""}
                    onClick={toggleDrawer('right', true)
                    }
                >
                    <span>Filter BY</span>
                </a>
                }
                <div className={styles.select_size_sort}>
                    <select value={sortValue} style={{ width: selectwidth }} name="sortprice" onChange={handleChange}>
                        <option value="">Sort By</option>
                        <option value="most popular">Most Popular</option>
                        <option value="asc">MRP - Low To High</option>
                        <option value="desc">MRP - High To Low</option>
                    </select>
                </div>
            </div>
            <div className={styles.shop_by_menu}>
                <ul>
                    {FiltersApplied.map((list, index) => (
                        <li key={index}><a onClick={cancelFilters(list.value, list.type)}>{list.label} <img src={cancel_btn} loading="lazy" alt="cross" width='12' height='12' /></a></li>
                    ))}
                </ul>
            </div>

        </div>
    )
}

export default Filters