import dynamic from "next/dist/shared/lib/dynamic";
import { memo } from "react";
import { useInView } from "react-intersection-observer";

const ListReviews = dynamic(() => import('./ListReviews'))
const ViewAllReviews = dynamic(() => import('./ViewAllReviews'))
const AddReviews = dynamic(() => import('./AddReview'))

const ListReviewsMemoized = memo(ListReviews)
const ViewAllReviewsMemoized = memo(ViewAllReviews)
const AddReviewsMemoized = memo(AddReviews)

const ProductReviewComponent = ({
    styles,
    preFixUrl,
    classes,
    setShowLoader,
    productcategory,
    eventCommonObj,
    product,
    currency
}) => {
    const { ref: reviewsSectionRef, inView: reviewsSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={reviewsSectionRef} id="reviewform" className={styles.pdp_sec12}>
            {reviewsSectionRefView ?
                <>
                    {product?.reviews?.items?.length > 0 ? (<>
                        <div className={styles.h2pdp_sec12}>Reviews</div>
                        <ListReviewsMemoized productReviews={product?.reviews} /> </>)
                        : null}

                    <div className={styles.review_view_main}>

                        {product?.reviews?.items?.length > 0 ? (<ViewAllReviewsMemoized productReviews={product?.reviews} productId={product?.id} reviewCount={product.review_count} preFixUrl={preFixUrl} />) : null}
                        <AddReviewsMemoized classes={classes} setShowLoader={setShowLoader} productcategory={productcategory} eventCommonObj={eventCommonObj} product={product} currency={currency} preFixUrl={preFixUrl} />
                    </div>
                </> : null}
        </div>
    )
}

export default ProductReviewComponent;