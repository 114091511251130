import { useInView } from "react-intersection-observer"

const Categorydescription = ({ styles, categorydescription }) => {
  const { ref: categoryDescriptionRef, inView: categoryDescriptionView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "0px 0px 300px 0px"
  })

  if (!categorydescription) {
    return null
  }

  return (
    <div ref={categoryDescriptionRef} className={styles.wrap_categories_row}>
      {categoryDescriptionView ? <div
        className={styles.category_product_colm}
        dangerouslySetInnerHTML={{
          __html: categorydescription,
        }}
      /> : null}
    </div>
  )

}

export default Categorydescription