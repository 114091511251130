import { useEffect } from 'react';
import { useRouter } from 'next/router';
import style from './404.module.css'

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    router.push('/');
  }, []);

  return (
    <div className={style.container}>
      <h1>404 - Page Not Found</h1>
      <p>Redirecting to the home page...</p>
    </div>
  );
}
