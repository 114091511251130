import { useInView } from "react-intersection-observer"
import dynamic from "next/dist/shared/lib/dynamic"

const YoutubeContentComponent = dynamic(() => import('./YoutubeContentComponent'))

const YoutubeContentSection = ({ videoURL, howToUse }) => {
    const { ref: youtubeContainerRef, inView: youtubeContainerRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={youtubeContainerRef} className="row">
            {youtubeContainerRefView ? <YoutubeContentComponent videoURL={videoURL} howToUse={howToUse} /> : null}
        </div>
    )
}

export default YoutubeContentSection