import Head from "next/head";

export default function JsonLdCategory(props) {
  const { cat: { data: { categories: { catItems }, storeConfig: { category_url_suffix, product_url_suffix } } } } = props.data;

  const hostCollection = `${process.env.NEXTAUTH_URL}/${catItems?.[0]?.url_path}${category_url_suffix}`;
  const itemList = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    name: catItems?.[0]?.name,
    url: hostCollection,
    "@breadcrumb": hostCollection,
    itemListElement: [],
  };
  const items = props?.prod?.data?.products?.items;
  items.forEach((item, i) => {
    const hostProduct = `${process.env.NEXTAUTH_URL}/${item.url_key}${product_url_suffix}`;
    const listItem = {
      "@type": "ListItem",
      position: i,
      item: {
        "@type": "product",
        name: item.name || "",
        image: item.media_gallery?.[0]?.url || "",
        description: item.meta_description || "",
        brand: "Kama Ayurveda",
        sku: item.sku,
        mpn: item.sku,
        price: item.price_range?.minimum_price?.final_price?.value || "",
        offers: {
          "@type": "AggregateOffer",
          lowPrice: item.price_range?.minimum_price?.final_price?.value || "",
          highPrice: item.price_range?.minimum_price?.regular_price?.value || "",
          priceCurrency: item.price?.regularPrice?.amount?.currency || "",
          offerCount: "1",
          availability: `https://schema.org/${item.stock_status == "IN_STOCK" ? "InStock" : "OutOfStock"
            }`,
        },
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: (item.rating_summary / 20).toFixed(1),
          reviewCount: item.review_count,
        },
        "breadcrumb": {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": i,
              "item": hostProduct,
              "name": item.name || ""

            }]
        }
      },
    };
    itemList.itemListElement.push(listItem);
  });
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(itemList),
        }}
      />
    </Head>
  );
}
