import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";

const OurCertifcatesSection = ({ productCertificate }) => {
    const { ref: ourCertifcatesSection, inView: ourCertifcatesSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    return (
        <div ref={ourCertifcatesSection} className={styles.pdp_sec10}>
            <div className={styles.h2pdp_sec10}>Our Certifications</div>
            {ourCertifcatesSectionView ? <ul
                dangerouslySetInnerHTML={{
                    __html: productCertificate,
                }}
            /> : null}
        </div>
    )
}

export default OurCertifcatesSection;