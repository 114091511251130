import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";
import dynamic from "next/dist/shared/lib/dynamic";

const OfferPincodeComponent = dynamic(() => import('./OfferPincodeComponent'))

const OfferPincodeSection = ({ websiteID, setShowLoader, eventCommonObj, anniversaryText, product, productprice, count, pdpOffers, modalStyle, classes, isMobileType, enableVariantData, productClinicallyTested, productHowToUse }) => {
    const { ref: offerPincodeSec, inView: offerPincodeSecView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    return (
        <div ref={offerPincodeSec} className={`row ` + styles["row"]}>
            {offerPincodeSecView ? <OfferPincodeComponent
                setShowLoader={setShowLoader}
                eventCommonObj={eventCommonObj}
                product={product}
                modalStyle={modalStyle}
                classes={classes}
                styles={styles}
                websiteID={websiteID}
                isMobileType={isMobileType}
                anniversaryText={anniversaryText}
                productprice={productprice}
                count={count}
                pdpOffers={pdpOffers}
                enableVariantData={enableVariantData}
                productClinicallyTested={productClinicallyTested}
                productHowToUse={productHowToUse}
            /> : null}
        </div>
    )
}

export default OfferPincodeSection;