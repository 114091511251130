
import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";

const PWABenefitsSection = ({ productPWABenifit }) => {
    const { ref: pwaBenefitsSection, inView: pwaBenefitsSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    return (
        <div ref={pwaBenefitsSection} className={styles.pdp_sec88}>
            {pwaBenefitsSectionView ?
                <div
                    className={styles.pdp_sec8}
                    dangerouslySetInnerHTML={{
                        __html: productPWABenifit,
                    }}
                />
                : null}
        </div>
    )
}

export default PWABenefitsSection;