import React from 'react';
interface NetQuantityProps {
    styles: any;
    product: any;
    productSize: any;
    selectSize: (sku: string, price: number, index: number, minimumPrice: number) => void;
}

const NetQuantity: React.FC<NetQuantityProps> = (props) => {
    const { styles, product, productSize, selectSize } = props;
    if (product?.primary_category?.toLowerCase() == 'gifting') {
        return null;
    }

    return (
        <>
            <div className={styles.pdp_sec3}>
                {product?.variants ? <div className={styles.h5pdp_quantity}>Available in: (Net Quantity)</div> : null}
                {product?.type_id === "simple" && product?.size?.label != '' ? (
                    <>
                        <div className={styles.h5pdp_quantity}>Available in: (Net Quantity)</div>
                        <ul>
                            <li key="0">
                                <a className={styles.active}>{product?.size?.label}</a>
                            </li>
                        </ul>
                    </>
                ) : null}
                <ul>
                    {product?.variants?.map((options: any, index: number) => (
                        <li key={index}>
                            {options.product.stock_status === "IN_STOCK" &&
                                (!options.product.salableqty.label ||
                                    options.product.salableqty.label > 0) ? (
                                <>
                                    <a
                                        className={productSize === index ? styles.active : ""}
                                        onClick={(e) =>
                                            selectSize(
                                                options.product.sku,
                                                options.product.price.regularPrice.amount.value,
                                                index,
                                                options.product.price_range?.minimum_price.regular_price.value
                                            )
                                        }
                                    >
                                        {options.attributes?.[0]?.label || ""}
                                    </a>
                                    {product?.sku == 'ITM00141_main' && (options.attributes?.[0]?.label).toLowerCase() == '30 ml' ? <span className={styles.saving_color}>33% Savings </span> : ''}
                                </>

                            ) : (
                                <>
                                    <a>{options.attributes?.[0]?.label || ""}</a>
                                    <span>Out of Stock</span>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default NetQuantity;
