import { useInView } from 'react-intersection-observer';
import classes from './list-page-content.module.css';
import dynamic from "next/dynamic";

const BlogPLPComponent = dynamic(() => import('./BlogPLPComponent'))

const BlogPLP = (props) => {
    const { ref: blogSection, inView: blogSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 300px 0px"
    })

    return (
        <div ref={blogSection} className={`category_listing_sec2_carousel ${classes['category_listing_sec2_carousel']}`}>
            {blogSectionView ? <BlogPLPComponent
                classes={classes}
                isMobileDevice={props.isMobileDevice}
                websiteurl={props.websiteurl}
                url_key={props.url_key}
                blogIdsArray={props.blogIdsArray}
            /> : null}
        </div>
    );
}

export default BlogPLP;