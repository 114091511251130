import { useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { getHyphenSeparatedString } from "../../lib/services/helpers/stringHelper";
import { useIsMobile } from "/lib/hooks/useIsMobile";
import { getReduxSessionStale } from "/lib/sessionHandler";
import { EventCommonObj } from "/lib/services/helpers/eventCommonObject";
import { ProductImpression } from "/lib/ga";
import { GET_PRODUCT_LIST_QUERY } from "../../fragments/productList/productList";

const useCustomHook = (props) => {
  const { slug, url_key, productdata, categoryid, categoryname, cookie, isSsrMobile, router } = props;
  const isMobileType = useIsMobile(isSsrMobile)
  const [storeid, setStoreid] = useState(productdata?.data.storeConfig.id || []);
  const [products, setProducts] = useState(productdata?.data.products.items || []);
  const menu = useSelector((state) => state.menu);
  const config = useSelector((state) => state.configdata);
  const [aggregations, setAggregations] = useState(productdata?.data.products.aggregations || [])
  const [prodVisibilityArr, setProdVisibilityArr] = useState(new Array(products?.length || 0).fill(false));
  const [isProductImpressionSent, setIsProductImpressionSent] = useState(new Array(products?.length || 0).fill(false));
  const [page, setPage] = useState(productdata?.data.products.page_info);
  const [aggregationsshow, setAggregationsShow] = useState(false);
  const [bIsFiltersApplied, applyFilters] = useState(false);
  const [initialload, setInitialload] = useState(true);
  const [objFilters, setObjFilters] = useState([]);
  const [FiltersApplied, setappliedFilters] = useState([]);
  const filters = { category_id: { eq: categoryid } };
  const path = process.env.MAGENTO_URL + "pub";
  const [bIsFiltersOpen, showFilters] = useState(false);
  const [sortvalue, setSortValue] = useState('default');
  const [selectwidth, setSelectWidth] = useState('80px');
  const [session, sessionLoading] = getReduxSessionStale();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [loadProducts, { called, loading, data, error }] =
    useLazyQuery(GET_PRODUCT_LIST_QUERY, {
      variables: { filters },
      notifyOnNetworkStatusChange: true,
    });

  const listName = {
    'asc': 'sort-asc',
    'desc': 'sort-desc',
    'most popular': categoryname,
    'default': categoryname
  }

  let head1, head2;

  let belowmenucategory = menu
    ?.filter(function (menu) {
      return ((slug?.includes(menu.url_key + "/") || slug?.includes(menu.url_key + "care/")) || (slug == menu.url_key));
    })
    .sort((a, b) => a.position - b.position).map(function (smenu) {
      head2 = smenu.children[0]?.name
      return smenu.children[0]?.children;
    });

  let belowmenuconsern = menu
    ?.filter(function (menu) {
      return (
        (slug?.includes(menu.url_key + "/") || slug?.includes(menu.url_key + "care/")) ||
        (slug == menu.url_key)
      );
    })
    .sort((a, b) => a.position - b.position)
    .map(function (smenu) {
      head1 = smenu.children[1]?.name
      return smenu.children[1]?.children;
    });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function handlerCloseFilters(check) {
    if (check) {
      setState({ ...state, ['right']: false });
    }

  }

  useEffect(() => {

    setProdVisibilityArr(new Array(products?.length || 0).fill(false));
    setIsProductImpressionSent(new Array(products?.length || 0).fill(false));

    const handleFilterReset = () => {
      handleChange({ target: { value: 'default', selectedIndex: 0 } });
      setappliedFilters([]);
      applyFilters(false);
    };

    router.events.on('routeChangeComplete', handleFilterReset);
    return () => {
      router.events.off('routeChangeComplete', handleFilterReset);
    };

  }, [url_key]);

  useEffect(() => {
    if (productdata) {
      setPage(productdata?.data.products.page_info);
      setProducts(productdata?.data.products.items);
      setStoreid(productdata?.data.storeConfig.id);
      setAggregations(productdata?.data.products.aggregations);
    }
  }, [productdata]);

  useEffect(() => {
    if (data) {
      const temp = products.concat(data?.products.items);
      setPage(data?.products.page_info);
      if (data?.products.page_info.current_page == 1) {
        setProducts(data?.products.items);
      } else {
        setProducts(temp);
      }
      setStoreid(data?.storeConfig.id || []);
      setAggregations(data?.products.aggregations || []);
      setAggregationsShow(true)
    }
    var temp = 0
    aggregations.map(function (item) {
      if (item.attribute_code == 'concern' || item.attribute_code == 'gender' || item.attribute_code == 'shelf_life'
        || item.attribute_code == 'skin_type') {
        if (item.options.length > 0) {
          temp++
        }
      }
    })
    if (temp > 0) {
      setAggregationsShow(true)
    }

  }, [data, !loading]);

  function reset() {
    applyFilters(false);
  }

  function handlerApplyFilters(objFilters) {
    setInitialload(false);
    setIsProductImpressionSent(new Array(products?.length || 0).fill(false))
    try {
      loadProducts({
        variables: {
          filters: objFilters,
        },
      });
    } catch (error) {
      console.log('error in load products', error);
    }
    handlerCloseFilters();
    applyFilters(true);
    setObjFilters(objFilters);
  }

  function appliedFilters(objFilters) {
    setappliedFilters(objFilters)
  }

  const cancelFilters = (value, type) => () => {
    setInitialload(false)
    let genderFilter;
    let concernFilters = [];
    let skinTypeFilters = [];
    let shelfTypeFilters = [];
    let tempFilters = FiltersApplied;
    FiltersApplied.map(function (item) {
      if (item.value != value && item.type == "Concern") {
        concernFilters.push(item.value);
      }
      if (item.value != value && item.type == "Skin Type") {
        skinTypeFilters.push(item.value);
      }
      if (item.value != value && item.type == "Gender") {
        genderFilter = item.value;
      }
      if (item.value != value && item.type == "Shelf Life") {
        shelfTypeFilters = item.value;
      }
    })
    const temp = {
      category_id: { eq: categoryid },
      concern: { in: concernFilters },
      gender: { eq: genderFilter },
      skin_type: { in: skinTypeFilters },
      shelf_life: { in: shelfTypeFilters },
    }
    try {
      loadProducts({
        variables: {
          filters: temp,
        },
      });
    } catch (error) {
      console.log('error', error);
    }

    tempFilters = tempFilters?.filter(function (item) {
      return item?.value !== value;
    });
    tempFilters?.length > 0 ? applyFilters(true) : applyFilters(false);
    setObjFilters(temp)
    setappliedFilters(tempFilters)
  }

  const handleChange = (e) => {
    setSortValue(e.target.value);
    setIsProductImpressionSent(new Array(products?.length || 0).fill(false))
    if (e.target.selectedIndex == 2 || e.target.selectedIndex == 3) {
      setSelectWidth('170px')
    } else if (e.target.selectedIndex == 1) {
      setSelectWidth('130px')
    } else {
      setSelectWidth('80px')
    }

  }

  const handleFetchMore = () => {
    if (loading || !page || page?.current_page === page?.total_pages) return;
    try {
      loadProducts({
        variables: {
          currentPage: page.current_page + 1
        },
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (products?.length > 0) {
      let productsArr = [];
      switch (sortvalue) {
        case 'asc':
          productsArr = products
            .slice()
            .sort((a, b) => a.price.regularPrice.amount.value > (b.price.regularPrice.amount.value) ? 1 : -1)
            .sort((a, b) => a.stock_status.localeCompare(b.stock_status))
          break;
        case 'desc':
          productsArr = products
            .slice()
            .sort((a, b) => a.price.regularPrice.amount.value < (b.price.regularPrice.amount.value) ? 1 : -1)
            .sort((a, b) => a.stock_status.localeCompare(b.stock_status))
          break;
        case 'default':
        case 'most popular':
        default:
          productsArr = products
            .slice()
            .sort((a, b) => a.stock_status.localeCompare(b.stock_status))
          break;
      }

      for (let i = 0; i < productsArr.length; isMobileType ? i += 2 : i += 3) {
        if (prodVisibilityArr[i] && !isProductImpressionSent[i]) {
          const eventCommonObj = EventCommonObj(session, cookie)
          const filtersAppliedValue = FiltersApplied.map(filter => `filter-${filter.type}-${getHyphenSeparatedString(filter.label)}`).join(' | ');
          ProductImpression({
            products: isMobileType ? productsArr.slice(i, i + 2) : productsArr.slice(i, i + 3),
            itemListName: categoryname || listName[sortvalue],
            itemListId: productsArr[i].id,
            itemCategory1: categoryname,
            itemCategory3: `sort-${getHyphenSeparatedString(sortvalue || 'default')}${bIsFiltersApplied ? ` | ${filtersAppliedValue}` : ' | filter-default'}`,
            productIndex: i,
            websiteID: storeid,
            eventCommonObj
          })
          isProductImpressionSent[i] = true;
          setIsProductImpressionSent([...isProductImpressionSent])
        }
      }
    }
  }, [prodVisibilityArr, products, bIsFiltersApplied, sortvalue, FiltersApplied, objFilters?.length]);

  const filtersAppliedValue = FiltersApplied?.map(filter => `filter-${filter?.type}-${getHyphenSeparatedString(filter?.label)}`).join(' | ');

  const ItemCategory3 = useMemo(() => {
    return `sort-${getHyphenSeparatedString(sortvalue || 'default')}${bIsFiltersApplied ? ` | ${filtersAppliedValue}` : ' | filter-default'}`
  }, [sortvalue, bIsFiltersApplied, filtersAppliedValue])


  return {
    ItemCategory3,
    session,
    loading,
    handleChange,
    handleFetchMore,
    path,
    bIsFiltersOpen,
    sortvalue,
    selectwidth,
    cancelFilters,
    FiltersApplied,
    appliedFilters,
    initialload,
    handlerApplyFilters,
    bIsFiltersApplied,
    handlerCloseFilters,
    state,
    toggleDrawer,
    config,
    prodVisibilityArr,
    setProdVisibilityArr,
    page,
    storeid,
    products,
    aggregations,
    aggregationsshow,
    reset,
    isMobileType,
    head1,
    head2,
    belowmenucategory,
    belowmenuconsern
  };
}

export default useCustomHook;
