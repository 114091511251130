import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";
import dynamic from "next/dynamic";

const BuyItWithProductsComponent = dynamic(() => import('./BuyItWithProductsComponent'))

const BuyItWithProducts = ({ eventCommonObj, buyitwithproducts, product, currency, productprice, addCrossSellToBag, checkedState, handleOnChange, childsku, count, crosssellCount, websiteID }) => {
    const { ref: useItWithRef, inView: useItWithRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={useItWithRef} className={styles.desk_pdp_sec9}>
            {useItWithRefView ? <BuyItWithProductsComponent
                eventCommonObj={eventCommonObj}
                buyitwithproducts={buyitwithproducts}
                product={product}
                currency={currency}
                productprice={productprice}
                addCrossSellToBag={addCrossSellToBag}
                checkedState={checkedState}
                handleOnChange={handleOnChange}
                childsku={childsku}
                count={count}
                crosssellCount={crosssellCount}
                websiteID={websiteID}
                styles={styles}
            /> : null}
        </div>
    )
}

export default BuyItWithProducts;