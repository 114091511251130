import React from "react";
import NotFound from '/pages/404'
import { gql } from "@apollo/client";
import { initializeApollo } from "/lib/apollo-client";
import Category from "/components/category";
import Product from "/components/product";
import { serialize } from 'cookie';
import { createClient } from 'redis';
import isMobileDetect from "/lib/services/helpers/isMobileDetect";
import { GET_PRODUCT_DETAIL_QUERY } from "../fragments/product/product";
import { GET_PRODUCT_LIST_QUERY } from "../fragments/productList/productList";
import { GET_CATEGORIES } from "/components/category/categories";

const CONTENT_TYPE = {
  CMS_PAGE: "CMS_PAGE",
  CATEGORY: "CATEGORY",
  PRODUCT: "PRODUCT",
  NOT_FOUND: "404",
};

const URLResolver = ({ isSsrMobile, type, origin, pdp, urlKey, plpdata }) => {
  if (type === CONTENT_TYPE.CMS_PAGE) {
    return <div>🥴 "CMS_PAGE" is not implemented in this sample.</div>;
  }

  if (type === CONTENT_TYPE.CATEGORY) {
    return <Category origin={origin} plpdata={plpdata} isSsrMobile={isSsrMobile} />;
  }

  if (type === CONTENT_TYPE.PRODUCT) {
    return <Product urlKey={urlKey} pdp={pdp} isSsrMobile={isSsrMobile} />;
  }
  return <NotFound />;
};


export async function getServerSideProps(ctx) {
  const client = createClient({
    url: process.env.REDIS_ENDPOINT
  });

  const { req, res, query, resolvedUrl } = ctx

  const origin = process.env.NEXTAUTH_URL;

  const storeB = typeof req.cookies['store'] == "undefined" ? "" : req.cookies['store']
  let storeVal = ''

  if (req.url.includes('/international') && !resolvedUrl.includes('/_url-resolver?pathname')) {

    storeVal = 'international'
    res.setHeader('Set-Cookie', serialize('store', 'international', { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: true }));
  }
  if (!req.url.includes('/international') && !resolvedUrl.includes('/_url-resolver?pathname')) {
    if (storeB != "international" || storeB == '') {
      storeVal = 'default'
      res.setHeader('Set-Cookie', serialize('store', 'default', { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: true }));
    }
  }

  let store = typeof req.cookies['store'] == "undefined" ? "" : req.cookies['store']
  store = (!store) ? storeVal : (store != storeVal && storeVal != '') ? storeVal : store

  // res?.setHeader("cache-control", "s-maxage=1, stale-while-revalidate");
  const apolloClient = initializeApollo("", store);
  let pathname = ""
  let urlKey = ""

  if (query?.pathname) {
    if (typeof query.pathname == "string" && query.url_path) {
      pathname = query.url_path.replace("international", "") + ".html"
      urlKey = query.url_key
    } else if (typeof query.pathname == "string") {
      pathname = query.pathname
      urlKey = query.pathname.split('.').shift()
    } else {
      pathname = query.pathname.join("/").replace("international", "");
      urlKey = query.pathname.pop().split(".").shift()
    }
  }

  if (query.pathname == "international" || pathname == "" || pathname == "/") {
    pathname = "/"
  }

  let productdata = []
  let pdp = []
  let cdata = []
  let plpdata = []

  /** ... if not, let's resolver the URL ...  */
  var str = pathname;
  var arr = ['.png', '.jpeg', '.jpg', '.svg', '.WebP'];

  function contains(target, pattern) {
    var value = 0;
    pattern.forEach(function (word) {
      value = value + target.includes(word);
    });
    return (value === 1)
  }

  if (contains(str, arr)) {
    return { props: { type: "404", pathname } };
  }

  let resolverdata, resolverdata_redis, type, id, categoryUID

  client.on('error', (err) => console.log('Redis Client Error', err));

  await client.connect();

  if (query?.key != process.env.NEXT_REDIS_CLEAN_KEY || query?.action != 'purge') {
    resolverdata_redis = JSON.parse(await client.get(`ur_${pathname}_${store}`));
  }

  if (resolverdata_redis) {
    type = resolverdata_redis?.type
    categoryUID = resolverdata_redis?.uid
  } else {
    resolverdata = await apolloClient.query({
      query: gql`
      query routeResolver($url: String!) {
        route(url: $url) {
          relative_url
          type
          ... on CategoryTree {
            uid
            name
          }
        }
      }
    `,
      variables: {
        url: pathname,
      },
    });

    /** ... if not found, return 404 ... */
    if (!resolverdata?.data.route) {
      if (res) res.statusCode = 404;
      await client.disconnect();
      return { props: { type: "404", pathname } };
    }

    await client.set(`ur_${pathname}_${store}`, JSON.stringify(resolverdata?.data.route))
    await client.expire(`ur_${pathname}_${store}`, 86400 * 7)
    type = resolverdata.data.route.type;
    categoryUID = resolverdata.data.route.uid
  }

  await client.disconnect();
  /** ... if the request is done by the server, then let's load the data in cache of SSR goodness ... */
  if (req) {
    // const {catdata} = await apolloClient.query({ query: APP_QUERY }) // Preload App Data
    switch (type) {
      case CONTENT_TYPE.CMS_PAGE:
        // Not implemented...
        break;
      case CONTENT_TYPE.CATEGORY:
        let category_data_redis = []

        client.on('error', (err) => console.log('Redis Client Error', err));

        await client.connect();

        if (query?.key == process.env.NEXT_REDIS_CLEAN_KEY && query?.action == 'purge') {
          await client.del(`category_data_${pathname}_${store}`)
          return {
            props: {
              isSsrMobile: isMobileDetect(req),
              type,
              plpdata: {},
              origin: {},
              pdp,
              initialApolloState: apolloClient.cache.extract(),
            },
          };
        }

        if (query?.key != process.env.NEXT_REDIS_CLEAN_KEY || query?.action != 'purge') {
          category_data_redis = JSON.parse(await client.get(`category_data_${pathname}_${store}`));
        }

        if (category_data_redis?.prod?.data?.products?.items?.length > 0) {
          res.setHeader('redis-status', `HIT,${Date.now()}`);
          plpdata = category_data_redis
        } else {
          res.setHeader('redis-status', `MISS,${Date.now()}`);

          cdata = await apolloClient.query({
            query: GET_CATEGORIES,
            variables: { filters: { category_uid: { eq: categoryUID } } },
          });

          /** If the category is set to show products, then load those products as well */

          if (categoryUID) {
            productdata = await apolloClient.query({
              query: GET_PRODUCT_LIST_QUERY,
              variables: { filters: { category_uid: { eq: categoryUID } } }
            });
          }

          plpdata = {
            cat: cdata,
            prod: productdata
          }

          await client.del(`blog_${urlKey}_${store}`);
          await client.set(`category_data_${pathname}_${store}`, JSON.stringify(plpdata))
          await client.expire(`category_data_${pathname}_${store}`, 900)

        }
        await client.disconnect();
        break;

      case CONTENT_TYPE.PRODUCT:

        let pdp_redis = []

        client.on('error', (err) => console.log('Redis Client Error', err));
        await client.connect();

        if (query?.key == process.env.NEXT_REDIS_CLEAN_KEY && query?.action == 'purge') {
          await client.del(`pdp_${urlKey}_${store}`)
          return {
            props: {
              isSsrMobile: isMobileDetect(req),
              id,
              type,
              urlKey,
              plpdata,
              origin,
              pdp: {},
              initialApolloState: apolloClient.cache.extract(),
            },
          };
        }

        if (query?.key != process.env.NEXT_REDIS_CLEAN_KEY || query?.action != 'purge') {
          pdp_redis = JSON.parse(await client.get(`pdp_${urlKey}_${store}`));
        }
        if (pdp_redis?.data?.products?.items?.length > 0) {
          res.setHeader('redis-status', `HIT,${Date.now()}`);
          pdp = pdp_redis
        } else {
          res.setHeader('redis-status', `MISS,${Date.now()}`);

          pdp = await apolloClient.query({
            query: GET_PRODUCT_DETAIL_QUERY,
            variables: { filters: { url_key: { eq: urlKey } } },
          });
          await client.del(`ingredients_data_${urlKey}_${store}`)
          await client.del(`askkama_data_${urlKey}_${store}`)
          await client.del(`blog_${urlKey}_${store}`);
          await client.set(`pdp_${urlKey}_${store}`, JSON.stringify(pdp))
          await client.expire(`pdp_${urlKey}_${store}`, 900)
          await client.disconnect();
        }
        break;
      default:
        break;
    }
  }

  /** Return Props */
  return {
    props: {
      isSsrMobile: isMobileDetect(req),
      type,
      plpdata,
      origin,
      pdp,
      urlKey,
      initialApolloState: apolloClient.cache.extract(), // load cached data from queries above into the initial state of the app
    }
  };
}

export default URLResolver;
