
import { useInView } from 'react-intersection-observer';
import dynamic from 'next/dist/shared/lib/dynamic';

const AddQuestionComponent = dynamic(() => import('./AddQuestionComponent'))

const AddQuestionSection = ({ preFixUrl, setShowLoader, productID }) => {
    const { ref: addQuestionSectionRef, inView: addQuestionSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={addQuestionSectionRef}>
            {addQuestionSectionRefView ? <AddQuestionComponent
                preFixUrl={preFixUrl}
                setShowLoader={setShowLoader}
                productID={productID}
            /> : null}
        </div>
    )
}

export default AddQuestionSection;