import React from 'react'
import { useInView } from 'react-intersection-observer';
import dynamic from "next/dynamic";

const GiftCardComponent = dynamic(() => import('./GiftCardComponent'))


const GiftCard = ({ styles, belowmenucategory, head2, websiteurl, belowmenuconsern, head1 }) => {
    const { ref: giftCardSection, inView: giftCardSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 300px 0px"
    })

    return (
        <div ref={giftCardSection} className={styles.wrap_categories_main}>
            {giftCardSectionView ? (
                <GiftCardComponent
                    styles={styles}
                    belowmenucategory={belowmenucategory}
                    head2={head2}
                    websiteurl={websiteurl}
                    belowmenuconsern={belowmenuconsern}
                    head1={head1}
                />
            ) : null}
        </div>
    )
}

export default GiftCard