import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";
import dynamic from "next/dist/shared/lib/dynamic";

const RecentlyViewedComponent = dynamic(() => import('./RecentlyViewedComponent'))

const RecentlyViewedSection = ({ recentview, productID, preFixUrl, currency, isMobileType }) => {
    const { ref: recentlyViewedSectionRef, inView: recentlyViewedSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={recentlyViewedSectionRef} className={`row ${styles["row"]}`}>
            {recentlyViewedSectionRefView ? <RecentlyViewedComponent
                recentview={recentview}
                productID={productID}
                preFixUrl={preFixUrl}
                currency={currency}
                isMobileType={isMobileType}
                styles={styles}
            /> : null}
        </div>
    )
}

export default RecentlyViewedSection;