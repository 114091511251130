import dynamic from 'next/dist/shared/lib/dynamic';
import { useInView } from 'react-intersection-observer';
const IngredientsComponent = dynamic(() => import('./IngredientsComponent'))

const IngredientsSection = ({ styles, isMobileType, urlKey, productId, productIngredientId }) => {
    const { ref: ingredientsSection, inView: ingredientsSectionView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    });

    return (
        <div ref={ingredientsSection}>
            {ingredientsSectionView ? <IngredientsComponent
                isMobileType={isMobileType}
                urlKey={urlKey}
                productId={productId}
                productIngredientId={productIngredientId}
            /> : null}
        </div>
    )
}

export default IngredientsSection