import dynamic from "next/dist/shared/lib/dynamic";
import { useInView } from "react-intersection-observer";
const KamaBlogComponent = dynamic(() => import('./KamaBlogComponent'))

const KamaBlogSection = ({ styles, urlKey, blogId, preFixUrl, eventCommonObj, isMobileType }) => {
    const { ref: kamaBlogSectionRef, inView: kamaBlogSectionRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={kamaBlogSectionRef} className={styles.pdp_sec14}>
            <div className={styles.h2pdp_sec14}>Kama Blog</div>
            {
                kamaBlogSectionRefView ? <KamaBlogComponent urlKey={urlKey} blogId={blogId} isMobileType={isMobileType} preFixUrl={preFixUrl} eventCommonObj={eventCommonObj} /> : null
            }
        </div>
    )
}

export default KamaBlogSection;