import { useInView } from "react-intersection-observer";
import styles from "../../Product.module.css";
import dynamic from "next/dist/shared/lib/dynamic";

const RelatedProductsComponent = dynamic(() => import('./RelatedProductComponent'))

const RelatedProducts = ({ urlKey, websiteID, isMobileType, eventCommonObj }) => {
    const { ref: relatedProductRef, inView: relatedProductRefView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "0px 0px 200px 0px"
    })

    return (
        <div ref={relatedProductRef} className={`row ${styles.pdp_product_scroll} ${styles.relatedProductPDP}`}>
            {relatedProductRefView ? <RelatedProductsComponent
                urlKey={urlKey}
                websiteID={websiteID}
                isMobileType={isMobileType}
                eventCommonObj={eventCommonObj}
                styles={styles}
            /> : null}
        </div>
    )
}

export default RelatedProducts;