import Head from "next/head";

export default function JsonLdProduct({ data, ProductSuffix }) {

  if (!data) return {};

  const { name, sku, stock_status, meta_description, rating_summary, review_count, url_key } = data;
  const host = `${process.env.NEXTAUTH_URL}/${url_key}/${ProductSuffix}`;
  const { url } = data.media_gallery[0].url;
  const final_price = data.price_range.minimum_price.final_price.value;
  const regular_price = data.price_range.minimum_price.regular_price.value;
  const INR = data.price.regularPrice.amount.currency;
  const ratingValue = (data.rating_summary / 20).toFixed(1);

  const articleStructuredData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: name,
    image: url,
    description: meta_description,
    brand: "Kama Ayurveda",
    sku: sku,
    mpn: sku,
    price: final_price,
    offers: {
      "@type": "AggregateOffer",
      lowPrice: final_price,
      highPrice: regular_price,
      priceCurrency: INR,
      offerCount: "1",
      availability: `https://schema.org/${stock_status == "IN_STOCK" ? "InStock" : "OutOfStock"
        }`,
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": ratingValue,
      "reviewCount": review_count
    },
    "breadcrumb": {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",

      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": host,
          name: name

        }]
    }


  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(articleStructuredData),
        }}
      />
    </Head>
  );
}
