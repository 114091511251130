import { gql } from "@apollo/client";
import { initializeApollo } from "../apollo-client";

export const getStoreConfigData = async(store) => {
    const apolloClient = initializeApollo("", store);

    const data = await apolloClient.query({
        query: gql`
            query getStoreConfig {
                storeConfig {
                    homepage_banner_offers_pdp
                    }
                }
        `
      });

      return data;
}

