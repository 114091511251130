import React from 'react'
import Carousel from "react-multi-carousel";
import { responsiveCategory } from '/lib/services/helpers/responsive';

const CategoryCarousel = ({ children }) => {
  return (
    <>
      <Carousel
        swipeable
        draggable
        showDots
        responsive={responsiveCategory}
        ssr // means to render carousel on server-side.
        infinite
        autoPlaySpeed={3000}
        autoPlay
        keyBoardControl
        partialVisible
        removeArrowOnDeviceType={["tablet", "mobile",]}
        additionalTransfrom={0}
      >
        {children}
      </Carousel>

    </>
  )
}

export default CategoryCarousel