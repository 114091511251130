const loadedScripts = {};
export function loadScript(src) {
    if (loadedScripts[src]) {
        return new Promise<void>(function (resolve, reject) {
            resolve();
        });
    }

    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        loadedScripts[src] = script;
        document.body.appendChild(script);
    });
}